import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import middlewarePipeline from "../middleware/middleware-pipeline";
import guest from "../middleware/guest";
import auth from "../middleware/auth";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      middleware: [auth],
    },
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      middleware: [guest],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/record",
    name: "record",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RecordView.vue"),
  },
  {
    path: "/invitewithdraw",
    name: "invitewithdraw",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/invitewithdraw.vue"),
  },
  {
    path: "/fastparity",
    name: "fastparity",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/fastparityView.vue"),
  },
  {
    path: "/parity",
    name: "parity",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/parityView.vue"),
  },
  {
    path: "/sapre",
    name: "sapre",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/sapreView.vue"),
  },
  {
    path: "/orderrecord",
    name: "ordrerecord",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/orderRecord.vue"),
  },
  {
    path: "/dice",
    name: "dice",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dice.vue"),
  },
  {
    path: "/andharbhar",
    name: "andharbhar",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/andharbhar.vue"),
  },
  {
    path: "/wheel",
    name: "wheel",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wheel.vue"),
  },
  //{
  //   path: '/jet',
  //   name: 'jet',

  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/jet.vue'),
  //   meta: { requiresHttps: false }
  // },
  {
    path: "/payment",
    name: "paymentVue",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PaymentVue.vue"),
  },
  {
    path: "/taskReward",
    name: "TaskReward",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TaskReward.vue"),
  },
  {
    path: "/CheckIn",
    name: "CheckIn",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CheckIn.vue"),
  },
  {
    path: "/MyLink",
    name: "MyLink",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyLink.vue"),
  },
  {
    path: "/privilage",
    name: "privilage",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/privilageView.vue"),
  },
  {
    path: "/IncomeDetails",
    name: "IncomeDetails",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/IncomeDetails.vue"),
  },
  {
    path: "/addupi",
    name: "addupi",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AddUpi.vue"),
  },
  {
    path: "/dairy",
    name: "dairyView",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dairyView.vue"),
  },
  {
    path: "/DailyIncome",
    name: "DAilyIncome",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DailyIncome.vue"),
  },
  {
    path: "/InviteRecord",
    name: "InviteRecord",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InviteRecord.vue"),
  },
  {
    path: "/mine",
    name: "mine",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MineView.vue"),
  },
  {
    path: "/recharge",
    name: "recharge",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RechargeView.vue"),
  },
  {
    path: "/promotion",
    name: "promotion",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PromoView.vue"),
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WithdrawView.vue"),
  },
  {
    path: "/rechargerecord",
    name: "rechargerecord",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RechargeRecord.vue"),
  },
  {
    path: "/withdrawalrecord",
    name: "withdrawalrecord",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WithdrawRecord.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      middleware: [guest],
    },
    alias: "/LR&RG",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterView.vue"),
  },
  {
    path: "/forgotpass",
    name: "forgotpass",
    meta: {
      middleware: [guest],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ForgotView.vue"),
  },
  {
    path: "/orders",
    name: "orders",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OrderView.vue"),
  },
  {
    path: "/reward",
    name: "reward",
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RewardView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
