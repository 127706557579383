export default function auth({ next, store }) {
  let isLoggedIn = false;
  if (localStorage.getItem("username") != null) {
    isLoggedIn = true;
  }
  if (!isLoggedIn) {
    return next({
      name: "login",
    });
  }

  return next();
}
