<template>
  <router-view />
</template>

<script>
export default {
  //
};
</script>

<style>
@import "@/assets/index.css";
@import "@/assets/bootstrap.min.css";
/* @import "@/assets/light.css"; */
@import "@/assets/chunk-vendors.efb085a2.css";
@import "@/assets/chunk-vendors.cf06751b.css";
@import "@/assets/bank.min.css";
@import "@/assets/app.b2eed9a6.css";
@import "@/assets/app.46643acf.css";
@import "@/assets/app.11875dd0.css";
@import "@/assets/app.1ee65bfb.css";
@import "@/assets/about.e7dddf65.css";
</style>
